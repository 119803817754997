(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/api-errors/assets/javascripts/api-calls/netto-loss.js') >= 0) return;  svs.modules.push('/components/tur/api-errors/assets/javascripts/api-calls/netto-loss.js');
'use strict';

const getNettoLossInfo = async () => {
  const options = {
    method: 'GET',
    path: '/gcs/1/activenetlossrestriction'
  };
  let response;
  let status;
  try {
    var _response;
    ({
      response,
      status
    } = await svs.core.jupiter.callAsync(options));
    return {
      error: status === 'success' ? null : ((_response = response) === null || _response === void 0 ? void 0 : _response.error) || 'error',
      data: status === 'success' ? response : null
    };
  } catch (err) {
    svs.tur.apierrors.logger.info("Error getting ".concat(options.path), err);
    return {
      error: err,
      data: null
    };
  }
};
svs.tur.apierrors.apiCalls = {
  getNettoLossInfo
};

 })(window);