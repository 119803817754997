(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tur/api-errors/assets/javascripts/apierrors.js') >= 0) return;  svs.modules.push('/components/tur/api-errors/assets/javascripts/apierrors.js');
"use strict";


(function (svs) {
  'use strict';

  svs.tur.apierrors.messageType = {
    ERROR: 'ERROR',
    REGULAR: 'REGULAR'
  };
  svs.tur.apierrors.logger = svs.core.log.getLogger('component:apierrors');
  svs.tur.apierrors.getErrorMessage = async (error, fallbackTitle, fallbackMessage) => {
    let errorCode;
    if (typeof error === 'object') {
      svs.tur.apierrors.logger.warn('API Error', error);
      errorCode = parseInt(String(error.error.code).replace(/\s/g, ''), 10);
    } else if (typeof error === 'number' || typeof error === 'string') {
      errorCode = parseInt(String(error).replace(/\s/g, ''), 10);
    } else {
      return {};
    }
    let messageType = svs.tur.apierrors.messageType.ERROR;
    let icon = 'exclamation-sign';
    let errorTitle = fallbackTitle || 'Fel';
    let errorMessage = fallbackMessage || 'Ett oväntat fel har inträffat.';
    let actionTitle;
    let actionCallback;
    let secondaryAction;
    switch (errorCode) {
      case 3107:
        svs.core.userSession.logout();
      case 100005:
        errorTitle = 'Inte Inloggad';
        errorMessage = 'Du måste logga in först.';
        break;
      case 8104:
      case 8107:
      case 8124:
        errorMessage = 'Omgången är stängd för spel, var vänlig försök senare.';
        break;
      case 8105:
        messageType = svs.tur.apierrors.messageType.REGULAR;
        errorTitle = 'Öppnar snart';
        errorMessage = 'Omgången har inte öppnat för spel, var vänlig försök senare.';
        break;
      case 8146:
        messageType = svs.tur.apierrors.messageType.REGULAR;
        errorTitle = 'Spelstopp passerat';
        errorMessage = 'Du kan inte spela efter passerat spelstopp. Nästa omgång har ännu inte öppnat. Vänligen försök igen senare!';
        break;
      case 8122:
        errorMessage = 'Spelet kunde inte annulleras p.g.a. att annulleringsstopp har inträffat.';
        break;
      case 100000:
        errorMessage = 'Hej! Du har skrivit in en felaktig webbadress. Kontrollera att adressen är rätt eller välj något av alternativen nedan.';
        break;
      case 100007:
        errorMessage = 'Hej! Du har skrivit in en felaktig webbadress eller använt en gammal länk (404). Kontrollera att adressen är rätt eller välj något av alternativen nedan.';
        break;
      case 22107:
        errorTitle = 'Laget är inte aktivt';
        errorMessage = 'Du kan tyvärr inte lägga spel på detta lag.';
        break;
      case 5117:
      case 6235:
        errorTitle = 'Inga rättigheter';
        errorMessage = 'Du är inte behörig att utföra begärd åtgärd.';
        break;
      case 6121:
        errorTitle = 'Lågt saldo';
        errorMessage = 'Du behöver sätta in pengar för att kunna betala ditt spel.';
        break;
      case 88:
      case 89:
      case 6149:
        errorTitle = 'Konto spärrat';
        errorMessage = 'Ditt konto är spärrat för spel var vänlig kontakta kundservice för mer information.';
        break;
      case 87:
      case 289:
      case 6289:
        messageType = svs.tur.apierrors.messageType.REGULAR;
        icon = 'deposit-limits';
        errorTitle = 'Snart kan du betala ditt spel';
        errorMessage = "Spelkoll \xE4r viktigt f\xF6r oss, d\xE4rf\xF6r beh\xF6ver du ange dina ins\xE4ttningsgr\xE4nser innan du kan spela.</br></br>\nIns\xE4ttningsgr\xE4nser ger dig b\xE4ttre koll p\xE5 dina ins\xE4ttningar och ditt spelande. Fundera \xF6ver vad som passar dig och ditt spelande och s\xE4tt gr\xE4nser per dag, vecka och m\xE5nad.";
        actionTitle = 'Sätt gränser';
        actionCallback = () => svs.utils.form.postToUrl(svs.core.urlMapping.get('myLimitsAllDeposits'), {
          returnUrl: window.location
        });
        break;
      case 8222:
      case 8225:
      case 8228:
      case 8223:
      case 8226:
      case 8229:
        errorTitle = 'Gräns överskriden';
        errorMessage = 'Högsta totala insats på denna omgång har överskridits.';
        break;
      case 8224:
      case 8227:
      case 8230:
        errorTitle = 'Gräns överskriden';
        errorMessage = 'Högsta totala dagliga insats på denna produkt har överskridits.';
        break;
      case 8239:
      case 8240:
        errorTitle = 'Gräns överskriden';
        errorMessage = 'Högsta antalet rader på denna omgång har överskridits.';
        break;
      case 135:
      case 5135:
      case 1019:
        errorMessage = "Du har en spelpaus aktiv.<br>G\xE5 till <a href=\"".concat(svs.core.urlMapping.get('gamingResponsibilityPause'), "\">Spelpaus</a> f\xF6r att l\xE4sa mer.");
        break;
      case 26103:
        errorMessage = "Spelet \xE4r redan inl\xF6st, du kan prova att g\xE5 till <a href=\"".concat(svs.core.urlMapping.get('myBetsHome'), "\">Mina Spel</a> och kontrollera om det finns i p\xE5g\xE5ende spel.");
        break;
      case 26107:
        errorMessage = 'Du har uppnått gränsen för hur många presentlotter som kan lösas in per dag. ' + 'Eller så har du angett en felaktig kod för många gånger. Försök igen samma tid imorgon. Vid bestående problem, vänligen kontakta Svenska Spel kundservice.';
        break;
      case 1000:
        errorMessage = 'Något gick fel, du kan prova att rensa dina kakor och ladda om sidan eller vänligen kontakta kundservice för mer information.';
        break;
      case 139:
      case 5139:
      case 95:
        {
          errorTitle = 'Just nu kan du inte spela <br/> eller sätta in pengar';
          const {
            data,
            error
          } = await svs.tur.apierrors.apiCalls.getNettoLossInfo();
          messageType = svs.tur.apierrors.messageType.REGULAR;
          icon = 'exclamation-sign';
          const netlossFallbackMessage = "<p> Visste du att du de senaste 24 timmarna har f\xF6rlorat mycket pengar? </p>\n          <p> Av omtanke om dig kan du d\xE4rf\xF6r inte s\xE4tta in pengar eller spela online eller i butik under 24 h. </p>\n          <p> Vill du prata med n\xE5gon om dina spelvanor? Du n\xE5r oss p\xE5 0770-11 11 11. </p>";
          if (!error) {
            const {
              amountLimit,
              endTime
            } = data;
            if (!amountLimit) {
              if (svs.core.detect.feature('ft-gaming-responsibility-contact')) {
                actionTitle = 'Stäng';
                secondaryAction = {
                  title: 'Kontakta oss',
                  callback: (e, forms, dialog) => {
                    dialog.close();
                    svs.utils.url.navigate('/spelkollskontakt');
                  }
                };
                errorMessage = "<p>Anledningen till att du just nu inte kan spela eller s\xE4tta in pengar \xE4r att du den senaste tiden spelat mer riskfyllt.</p>\n                  <p>Innan du kan spela vidare beh\xF6ver du kontakta oss f\xF6r att prata om dina spelvanor. Du kan v\xE4lja mellan att chatta eller prata med n\xE5gon av v\xE5ra omsorgskommunikat\xF6rer.</p>";
              } else {
                errorMessage = "<p>Vi har f\xF6rs\xF6kt kontakta dig f\xF6r att hitta tid f\xF6r ett samtal om hur du k\xE4nner kring dina spelvanor. Tills vi f\xE5r tag p\xE5 dig kan du inte spela eller s\xE4tta in pengar.</p>\n                  <p>Kontakta oss p\xE5 0498-20 02 91 f\xF6r att hitta tid f\xF6r ett samtal.</p>";
              }
            } else if ((endTime === null || endTime === void 0 ? void 0 : endTime.length) > 0) {
              var _svs$utils$date$Short;
              const displayTime = (_svs$utils$date$Short = svs.utils.date.ShortDateAndTime(endTime, false, true)) === null || _svs$utils$date$Short === void 0 ? void 0 : _svs$utils$date$Short.toLowerCase();

              errorMessage = "<p> Visste du att du de senaste 24 timmarna har f\xF6rlorat mer \xE4n ".concat(svs.utils.format.Currency(amountLimit), " kr? </p> \n            <p> Av omtanke om dig kan du d\xE4rf\xF6r inte s\xE4tta in pengar eller spela online eller i butik just nu. Du kan spela igen ").concat(displayTime, ". </p>\n            <p> Vill du prata med n\xE5gon om dina spelvanor? Du n\xE5r oss p\xE5 0770-11 11 11. </p>");
            } else {
              errorMessage = netlossFallbackMessage;
            }
          } else {
            errorMessage = netlossFallbackMessage;
            svs.tur.apierrors.logger.info("Unable to get nettoloss info: ".concat(error === null || error === void 0 ? void 0 : error.code));
          }
        }
        break;
      default:
        svs.tur.apierrors.logger.info("Unable to find error-message from code: ".concat(errorCode));
    }
    return {
      icon,
      errorTitle,
      errorMessage,
      messageType,
      actionTitle,
      actionCallback,
      secondaryAction
    };
  };
  svs.tur.apierrors.showErrorMessage = async _ref => {
    let {
      response,
      fallbackTitle,
      fallbackMessage,
      onConfirm
    } = _ref;
    const {
      icon,
      errorTitle,
      errorMessage,
      messageType,
      actionTitle = 'Ok',
      actionCallback = onConfirm,
      secondaryAction
    } = await svs.tur.apierrors.getErrorMessage(response, fallbackTitle, fallbackMessage);
    svs.ui.dialog.api.close();
    const actions = [{
      title: actionTitle,
      callback: (e, forms, dialog) => {
        dialog.close();
        actionCallback && actionCallback();
      }
    }];
    secondaryAction && actions.push(secondaryAction);
    svs.ui.dialog.api.add(new svs.ui.dialog.Confirm({
      branding: svs.ui.dialog.branding.TUR,
      type: messageType === svs.tur.apierrors.messageType.REGULAR ? svs.ui.dialog.type.DEFAULT : svs.ui.dialog.type.ERROR,
      icon,
      title: errorTitle,
      message: [{
        type: svs.ui.dialog.message.TEXT,
        text: errorMessage
      }],
      actions
    }));
  };
})(svs, _);

 })(window);